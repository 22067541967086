<template>
  <div class="availability">
    <div class="number">{{ number }}</div>

    <div class="field date">
      <p>{{ labelDate || 'xxxx-xx-xx' }}</p>
      <input
        type="date"
        :min="currentDate"
        v-model="labelDate"
        @change="changeDate"
      />

      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 6C4 5.44772 4.44772 5 5 5H11C11.5523 5 12 5.44772 12 6V12H4V6Z"
          stroke="#F28C00"
          stroke-width="2"
        />
        <path
          d="M6 3V5"
          stroke="#F28C00"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M11 7H4" stroke="#F28C00" stroke-width="2" />
        <path
          d="M10 3V5"
          stroke="#F28C00"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>

    <div class="field time">
      <p>{{ labelTime || 'xx:xx' }}</p>

      <select v-model="labelTime" @change="changeTime">
        <option v-for="option in timeOptions" :key="option">
          {{ option }}
        </option>
      </select>

      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="8" cy="8" r="4" stroke="#F28C00" stroke-width="2" />
        <path d="M8 5.5V8H10" stroke="#F28C00" />
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import moment from 'moment'

export default defineComponent({
  props: {
    number: {
      number: String,
      default: '1',
    },
    date: {
      number: String,
      default: '',
    },
    time: {
      number: String,
      default: '',
    },
  },
  setup(props, ctx) {
    const labelDate = ref(props.date)
    const labelTime = ref(props.time)
    const timeOptions = ref<Array<string>>([])
    const currentDate = moment().format('YYYY-MM-DD')

    for (let h = 9; h < 19; h++) {
      for (let m = 0; m < 60; m += 10) {
        const hours = h.toString().padStart(2, '0')
        const minutes = m.toString().padStart(2, '0')
        timeOptions.value.push(`${hours}:${minutes}`)
      }
    }

    timeOptions.value.push('19:00')

    const changeDate = () => {
      ctx.emit('update:date', labelDate.value)
    }

    const changeTime = () => {
      ctx.emit('update:time', labelTime.value)
    }

    return {
      timeOptions,
      changeDate,
      changeTime,
      labelTime,
      labelDate,
      currentDate,
    }
  },
})
</script>

<style lang="scss" scoped>
.availability {
  display: flex;
  align-items: center;
  background: $athens-gray;
  margin-bottom: 4px;
}

.field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 7px;
  position: relative;

  input,
  select {
    @include get-all-space;
    z-index: 10;
    opacity: 0;
  }

  p {
    color: $rock-blue;
    font-size: 14px;
  }
}

.number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: $hippie-blue;
  color: $c-white;
}

.date {
  flex: 1;
  border-right: 1px solid $botticelli;
  height: 40px;
}

.time {
  flex: 1;
  height: 40px;
}

input[type='date'] {
  position: absolute;
}

/* create a new arrow, because we are going to mess up the native one
see "List of symbols" below if you want another, you could also try to add a font-awesome icon.. */
input[type='date']:after {
  content: '\25BC';
  color: #555;
  padding: 0 5px;
}

/* change color of symbol on hover */
input[type='date']:hover:after {
  color: #bf1400;
}

/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

/* adjust increase/decrease button */
input[type='date']::-webkit-inner-spin-button {
  z-index: 1;
}

/* adjust clear button */
input[type='date']::-webkit-clear-button {
  z-index: 1;
}
</style>
