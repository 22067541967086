
import { defineComponent, ref } from 'vue'
import moment from 'moment'

export default defineComponent({
  props: {
    number: {
      number: String,
      default: '1',
    },
    date: {
      number: String,
      default: '',
    },
    time: {
      number: String,
      default: '',
    },
  },
  setup(props, ctx) {
    const labelDate = ref(props.date)
    const labelTime = ref(props.time)
    const timeOptions = ref<Array<string>>([])
    const currentDate = moment().format('YYYY-MM-DD')

    for (let h = 9; h < 19; h++) {
      for (let m = 0; m < 60; m += 10) {
        const hours = h.toString().padStart(2, '0')
        const minutes = m.toString().padStart(2, '0')
        timeOptions.value.push(`${hours}:${minutes}`)
      }
    }

    timeOptions.value.push('19:00')

    const changeDate = () => {
      ctx.emit('update:date', labelDate.value)
    }

    const changeTime = () => {
      ctx.emit('update:time', labelTime.value)
    }

    return {
      timeOptions,
      changeDate,
      changeTime,
      labelTime,
      labelDate,
      currentDate,
    }
  },
})
